export const CHROME_EXTENSION_STORE_URL =
    "https://chrome.google.com/webstore/detail/recall-browser-extension/ldbooahljamnocpaahaidnmlgfklbben"

export const FIREFOX_RECALL_EXTENSION_STORE_URL =
    "https://addons.mozilla.org/en-US/firefox/addon/getrecall/"

export const APP_STORE_URL = "https://apps.apple.com/app/recall-summarize-save/id6445893722"
export const PLAY_STORE_URL = "https://play.google.com/store/apps/details?id=com.recall.wiki"

export const DISCORD_URL = "https://discord.gg/KDgvbcNpt9"
export const TWITTER_URL = "https://twitter.com/getRecallAI"
export const LINKEDIN_URL = "https://www.linkedin.com/company/getrecall-ai/"

export const ROUTES = {
    BLOG: "/blog",
    FAQ: "/faq",
    DOCS: "https://docs.getrecall.ai",
    PRICING: "https://app.getrecall.ai/pricing",
    PRIVACY_POLICY: "/privacy-policy",
    SUMMARIES: "/summaries",
    SIGNUP: "https://app.getrecall.ai/signup",
    LOGIN: "https://app.getrecall.ai/login",
    HOME: "/",
    ROADMAP: "https://feedback.getrecall.ai/",
    FEATURE_REQUEST: "https://feedback.getrecall.ai/feature-requests",
    CHANGE_LOG: "https://feedback.getrecall.ai/changelog",
    BLOGS: {
        SPACED_REPETITION: "/post/supercharge-your-memory-using-spaced-repetition-2023",
        YOUTUBE_VIDE_SUMMARIZER: "/post/save-hours-with-the-ultimate-youtube-video-summarizer",
        TOP_LONGEVITY_PODCASTS_2023: "/post/top-10-longevity-podcasts-of-2023",
        PDF_SUMMARIZER: "/post/pdf-summarizer",
        BEST_YOUTUBE_SUMMARIZER: "/post/10-best-youtube-video-summarizers-in-2024",
    },
}
